import { template } from "@ember/template-compiler";
import { Input } from '@ember/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { on } from '@ember/modifier';
import Component from '@glimmer/component';
const hash = (k1, v1)=>{
    return {
        [k1]: v1
    };
};
export default class SearchWidgetComponent extends Component {
    @tracked
    query = '';
    @service
    router;
    @action
    search(event1) {
        event1.preventDefault();
        this.router.transitionTo('search', {
            queryParams: {
                q: this.query
            }
        });
    }
    static{
        template(`
    <div class='widget-sidebar'>
      <form class='sidebar-search-form' {{on 'submit' this.search}}>
        <div class='submit-input full blue'>
          <Input
            @route='search'
            @query={{hash 'q' this.search}}
            type='text'
            id='q'
            name='q'
            placeholder='Search articles here...'
            @value={{this.query}}
          />
          <button class='submit-input-button'>
            <svg class='search-icon small'>
              <use href='#svg-search'></use>
            </svg>
          </button>
        </div>
      </form>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
