import { setClient } from 'glimmer-apollo';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import config from 'velocity/config/environment';
import supabase from './supabase';

export default function setupApolloClient(context: object): void {
  const httpLink = createHttpLink({
    uri: `https://graphql.contentful.com/content/v1/spaces/g4p7iq2vydf4?access_token=${config.contentfulPublicKey}`,
  });
  const cache = new InMemoryCache();
  const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
  });

  setClient(context, apolloClient);

  const supabaseHttpLink = createHttpLink({
    uri: 'https://qptaeyqlyyjwostilhvy.supabase.co/graphql/v1',
  });
  const authLink = setContext(async (_, { headers }) => {
    const accessToken = (await supabase.auth.getSession()).data.session
      ?.access_token;
    const bearer = `Bearer ${accessToken}`;
    return {
      headers: {
        ...headers,
        Authorization: accessToken ? bearer : '',
        apiKey: config.supabaseKey,
      },
    };
  });

  const supabaseApolloClient = new ApolloClient({
    link: authLink.concat(supabaseHttpLink),
    cache,
  });

  setClient(context, supabaseApolloClient, 'supabase-client');
}
