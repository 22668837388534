import { template } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { formatDate } from '../../../helpers/format-date';
const eq = (a1, b1)=>a1 === b1;
const hash = (k1, v1)=>{
    return {
        [k1]: v1
    };
};
export default template(`
  <div class='slider-item slider-item-1' ...attributes style="background-image: url('{{@article.image.url}}');">
    <div class='slider-item-wrap'>
      <div class='post-preview huge centered ember {{if (eq @article.primaryTag "Ember.js") "ember"}}'>
        <LinkTo @route='search' @query={{hash 'tag' @article.primaryTag}} class='tag-ornament'>
          {{@article.primaryTag}}
        </LinkTo>
        <LinkTo @route='articles.post' @model={{@article.slug}} class='post-preview-title'>{{@article.title}}</LinkTo>
        <div class='post-author-info-wrap'>
          <LinkTo @route='search' @query={{hash 'authorName' @article.author.name}}>
            <figure class='user-avatar tiny liquid'>
              <img src='{{@article.author.avatar.url}}' alt='{{@article.author.name}}' />
            </figure>
          </LinkTo>
          <p class='post-author-info small light'>By
            <LinkTo @route='search' @query={{hash 'authorName' @article.author.name}} class='post-author'>
              {{@article.author.name}}
            </LinkTo>
            <span class='separator'>|</span>{{formatDate @article.publishedDate}}
          </p>
        </div>
        <div class='break'></div>
        <LinkTo @route='articles.post' @model='{{@article.slug}}' class='button blue'>
          Go to the article
          <div class='button-ornament'>
            <svg class='arrow-icon medium'>
              <use href='#svg-arrow-medium'></use>
            </svg>
            <svg class='cross-icon small'>
              <use href='#svg-cross-small'></use>
            </svg>
          </div>
        </LinkTo>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
