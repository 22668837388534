import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import supabase from 'velocity/supabase';

import type { AuthChangeEvent, Session } from '@supabase/supabase-js';

export default class CurrentUser extends Service {
  @tracked session: Session | null = null;

  constructor(...args: any[]) {
    super(...args);

    supabase.auth.onAuthStateChange(
      (event: AuthChangeEvent, session: Session | null) => {
        this.session = session || null;
      },
    );
  }

  get isLoggedIn() {
    return !!(this.session?.access_token && this.session?.user);
  }

  get displayName() {
    return this.session?.user?.user_metadata['full_name'];
  }

  get profilePhoto() {
    return this.session?.user?.user_metadata['avatar_url'];
  }
}

declare module '@ember/service' {
  interface Registry {
    currentUser: CurrentUser;
  }
}
