import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
import { service } from '@ember/service';
import AccountHeader from './account-header';
import { LinkTo } from '@ember/routing';
import MobileNav from './mobile-nav';
export default class TopNavComponent extends Component {
    @service
    router;
    get isHomePage() {
        return this.router.currentRouteName === 'index';
    }
    static{
        template(`
    <AccountHeader />
    <!-- <MobileNav @isMobileMenuOpen={{@isMobileMenuOpen}} @openSearch={{@openSearch}} /> -->
    <nav class='navigation-wrap {{if this.isHomePage "stick-on-top"}}'>
      <div class='navigation grid-limit'>
        <div class='logo'>
          <div class='logo-text'>
            <LinkTo @route='application'>
              <h2 class='logo-title'>TerminalVelo<span class='highlight'>.city</span></h2>
            </LinkTo>
            <p class='logo-info'></p>
          </div>
        </div>
        <div class='search-button search-popup-open' {{on 'click' (fn @openSearch true)}}>
          <svg class='search-icon'>
            <use href='#svg-search'></use>
          </svg>
        </div>
        <ul class='main-menu'>
          <li class='main-menu-item'>
            <LinkTo @route='index' class='main-menu-item-link'>Home</LinkTo>
          </li>
          <li class='main-menu-item'>
            <LinkTo @route='articles' class='main-menu-item-link'>
              Articles
              <!-- <svg class='arrow-icon'>
                <use href='#svg-arrow'></use>
              </svg> -->
            </LinkTo>
            <!-- <NewsSubmenu /> -->
          </li>
          <li class='main-menu-item'>
            <LinkTo @route='about' class='main-menu-item-link'>About</LinkTo>
          </li>
        </ul>
      </div>
    </nav>
    <div class='mobile-menu-pull mobile-menu-open' {{on 'click' (fn @openMobileMenu true)}}>
      <svg class='menu-pull-icon'>
        <use href='#svg-menu-pull'></use>
      </svg>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
