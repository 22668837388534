import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import TopNav from '../components/layout/top-nav/main';
import Footer from '../components/layout/footer';
import SvgSheet from '../helpers/svgsheet';
import Search from '../components/search';
import { keyResponder, onKey } from 'ember-keyboard';
let ApplicationRouteComponent = @keyResponder({
    priority: 1
})
class ApplicationRouteComponent extends Component {
    @tracked
    isBagOpen = false;
    @tracked
    isSearchOpen = false;
    @tracked
    isMobileMenuOpen = false;
    @action
    setIsBagOpen(state1) {
        this.isBagOpen = state1;
    }
    @action
    setIsSearchOpen(state1) {
        this.isSearchOpen = state1;
    }
    @action
    setIsMobileMenuOpen(state1) {
        this.isMobileMenuOpen = state1;
    }
    @onKey('w')
    scrollUp() {
        scrollBy(0, -34);
    }
    @onKey('shift+w')
    pageUp() {
        scrollBy(0, -959);
    }
    @onKey('s')
    scrollDown() {
        scrollBy(0, 34);
    }
    @onKey('shift+s')
    pageDown() {
        scrollBy(0, 959);
    }
    @onKey('f')
    async openSearch() {
        await timeout(20);
        this.setIsSearchOpen(true);
    }
    static{
        template(`
    <TopNav
      @openBag={{this.setIsBagOpen}}
      @openSearch={{this.setIsSearchOpen}}
      @openMobileMenu={{this.setIsMobileMenuOpen}}
      @isMobileMenuOpen={{this.isMobileMenuOpen}}
      @openSearch={{this.openSearch}}
    />
    {{#if this.isSearchOpen}}
      <Search @isSearchOpen={{this.isSearchOpen}} @closeSearch={{this.setIsSearchOpen}} />
    {{/if}}
    {{outlet}}
    <Footer />
    {{{SvgSheet}}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(ApplicationRouteComponent);
function timeout(wait1 = 0) {
    return new Promise((resolve1)=>{
        setTimeout(resolve1.bind(this, wait1), wait1);
    });
}
