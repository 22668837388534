import Route from '@ember/routing/route';
import { useQuery } from 'glimmer-apollo';
import { ARTICLES_BY_AUTHOR } from 'velocity/gql/queries/articles-by-author';
import { ARTICLES_BY_TAG } from 'velocity/gql/queries/articles-by-tag';
import { ARTICLES_BY_QUERY } from 'velocity/gql/queries/articles-by-query';
import { tracked } from '@glimmer/tracking';

export default class Post extends Route {
  @tracked searchTerm;
  queryParams = {
    q: {
      refreshModel: true,
    },
    tag: {
      refreshModel: true,
    },
    authorName: {
      refreshModel: true,
    },
  };
  async model({ q, tag, authorName }) {
    if (q) {
      this.searchTerm = q;
      return await this.searchByQuery(q);
    }
    if (tag) {
      this.searchTerm = tag;
      return await this.searchByTag(tag);
    }
    if (authorName) {
      this.searchTerm = authorName;
      return await this.searchByAuthor(authorName);
    }
  }

  async searchByQuery(q) {
    this.query = useQuery(this, () => [
      ARTICLES_BY_QUERY,
      {
        variables: { query: q },
      },
    ]);
    await this.query.promise;
    return this.query.data?.articles?.items;
  }

  async searchByTag(tag) {
    this.query = useQuery(this, () => [
      ARTICLES_BY_TAG,
      {
        variables: { tag: tag },
      },
    ]);
    await this.query.promise;
    return this.query.data?.articles?.items;
  }

  async searchByAuthor(authorName) {
    this.query = useQuery(this, () => [
      ARTICLES_BY_AUTHOR,
      {
        variables: { name: authorName },
      },
    ]);
    await this.query.promise;
    return this.query.data?.searchedAuthor?.items[0]?.linkedFrom?.articles
      ?.items;
  }
}
