import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import BannerComponent from '../components/layout/banner/main';
let AboutRouteComponent = class AboutRouteComponent extends Component {
    static{
        template(`
    <BannerComponent @theme='gaming-news' @title='About' />
    <div class='layout-content-1 layout-item-3-1 grid-limit'>
      <div class='layout-body'>
        <div class='layout-item gutter-big'>
          {{#each @model.recentArticles.items as |article|}}
            <h1>{{article.title}}</h1>
          {{/each}}
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AboutRouteComponent);
