// noinspection SpellCheckingInspection

import EmberRouter from '@ember/routing/router';
import config from 'velocity/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('search');
  this.route('articles', function () {
    this.route('post', { path: '/:slug' });
  });
  this.route('about');
  this.route('privacy-policy');
  this.route('terms-and-conditions');
  this.route('404', { path: '/*' });
});
