import { template } from "@ember/template-compiler";
export default template(`
  <div class='widget-sidebar'>
    <div class='section-title-wrap blue'>
      <h2 class='section-title medium'>Popular Posts</h2>
      <div class='section-title-separator'></div>
    </div>
    <div class='post-preview-showcase grid-1col centered gutter-small'>
      <div class='post-preview tiny gaming-news'>
        <a href='/articles/article-slug'>
          <div class='post-preview-img-wrap'>
            <figure class='post-preview-img liquid'>
              <img src='/img/posts/01.jpg' alt='post-01' />
            </figure>
          </div>
        </a>
        <a href='/articles/article-slug' class='post-preview-title'>The Clash of Dragons is breaking record sales in USA
          and Japan</a>
        <div class='post-author-info-wrap'>
          <p class='post-author-info small light'>By
            <a href='/search' class='post-author'>Dexter</a><span class='separator'>|</span>Dec 15th, 2018</p>
        </div>
      </div>
      <div class='post-preview tiny game-review'>
        <a href='/reviews/review-article-slug'>
          <div class='post-preview-img-wrap'>
            <figure class='post-preview-img liquid'>
              <img src='/img/posts/25.jpg' alt='post-25' />
            </figure>
            <div class='review-rating'>
              <div id='sidebar-rate-1' class='arc tiny'></div>
            </div>
          </div>
        </a>
        <a href='/reviews/review-article-slug' class='post-preview-title'>"Legend of Kenshii II" is a bit green for now</a>
        <div class='post-author-info-wrap'>
          <p class='post-author-info small light'>By
            <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>Dec 15th, 2018</p>
        </div>
      </div>
      <div class='post-preview tiny movie-news'>
        <a href='post-v3.html'>
          <div class='post-preview-img-wrap'>
            <figure class='post-preview-img liquid'>
              <img src='/img/posts/12.jpg' alt='post-12' />
            </figure>
            <div class='rating-ornament'>
              <div class='rating-ornament-item'>
                <svg class='rating-ornament-icon'>
                  <use href='#svg-star'></use>
                </svg>
              </div>
              <div class='rating-ornament-item'>
                <svg class='rating-ornament-icon'>
                  <use href='#svg-star'></use>
                </svg>
              </div>
              <div class='rating-ornament-item'>
                <svg class='rating-ornament-icon'>
                  <use href='#svg-star'></use>
                </svg>
              </div>
              <div class='rating-ornament-item'>
                <svg class='rating-ornament-icon empty'>
                  <use href='#svg-star'></use>
                </svg>
              </div>
              <div class='rating-ornament-item'>
                <svg class='rating-ornament-icon empty'>
                  <use href='#svg-star'></use>
                </svg>
              </div>
            </div>
          </div>
        </a>
        <a href='post-v3.html' class='post-preview-title'>We reviewed the "Guardians of the Universe" movie</a>
        <div class='post-author-info-wrap'>
          <p class='post-author-info small light'>By
            <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>Dec 15th, 2018</p>
        </div>
      </div>
      <div class='post-preview tiny gaming-news'>
        <a href='/articles/article-slug'>
          <div class='post-preview-img-wrap'>
            <figure class='post-preview-img liquid'>
              <img src='/img/posts/17.jpg' alt='post-17' />
            </figure>
          </div>
        </a>
        <a href='/articles/article-slug' class='post-preview-title'>Jazzstar announced that the GTE5 for PC is delayed</a>
        <div class='post-author-info-wrap'>
          <p class='post-author-info small light'>By
            <a href='/search' class='post-author'>Dexter</a><span class='separator'>|</span>Dec 15th, 2018</p>
        </div>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
