import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
export default class PromoComponent extends Component {
    @service
    metrics;
    @action
    tEv() {
        this.metrics.trackEvent({
            name: 'clickPromo'
        });
    }
    static{
        template(`
    <div class='widget-sidebar'>
      <div class='section-title-wrap red'>
        <h2 class='section-title medium'>Affiliate</h2>
        <div class='section-title-separator'></div>
      </div>
      <div class='promo-advert'>
        <a href='https://www.simpleanalytics.com/?referral=tegif' {{on 'click' this.tEv}} target='_blank'>
          <img
            class='promo-advert-img'
            src='https://images.ctfassets.net/g4p7iq2vydf4/2qQaRB1mMzHibXr8f3p1Bd/1b8dd7e5ea523f29722942c3250b4c97/simple-analytics.png'
            alt='Simple Analytics'
          />
        </a>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
