import { template } from "@ember/template-compiler";
export default template(`
  <style>
    .bubble-ornament.medium .github-icon { width: 60px; left: 2px; top: 1px; }
  </style>
  <div class='widget-sidebar'>
    <div class='section-title-wrap blue'>
      <h2 class='section-title medium'>Social Pixel</h2>
      <div class='section-title-separator'></div>
    </div>
    <div class='social-links medium centered'>
      <a href='https://twitter.com/jhliberty' target='_blank' class='bubble-ornament medium twt'>
        <svg class='twitter-icon big'>
          <use href='#svg-twitter'></use>
        </svg>
      </a>
      <a href='https://twitch.tv/undarkgaming' target='_blank' class='bubble-ornament medium twitch'>
        <svg class='twitch-icon big'>
          <use href='#svg-twitch'></use>
        </svg>
      </a>
      <a href='https://github.com/jhliberty' target='_blank' class='bubble-ornament medium black'>
        <svg class='github-icon'>
          <use href='#svg-github'></use>
        </svg>
      </a>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
