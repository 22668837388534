import Route from '@ember/routing/route';
import { useQuery } from 'glimmer-apollo';
import { GET_ARTICLE } from '../../gql/queries/article-post';

export default class Post extends Route {
  async model({ slug }) {
    this.query = useQuery(this, () => [
      GET_ARTICLE,
      {
        variables: { slug },
      },
    ]);
    await this.query.promise;
    return this.query.data?.articles?.items[0];
  }
}
