import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import NewsSliderMain from '../components/layout/news-slider/main';
import SubscribeBanner from '../components/layout/subscribe-banner';
import Promo from '../components/widget/sidebar/promo';
import SocialComponent from '../components/widget/sidebar/social';
import PopularPosts from '../components/widget/sidebar/popular-posts';
import ArticlesList from '../components/widget/main/articles-list';
import { LinkTo } from '@ember/routing';
import Search from '../components/widget/sidebar/search';
let IndexRouteComponent = class IndexRouteComponent extends Component {
    static{
        template(`
    <NewsSliderMain @articles={{@model.featuredArticles.items}} />
    <div class='layout-content-1 layout-item-3-1 search-pad grid-limit'>
      <div class='layout-body layout-item centered'>
        <ArticlesList @articles={{@model.articles.items}} />
      </div>
      <div class='layout-sidebar layout-item gutter-medium'>
        <Search />
        <SocialComponent />
        <Promo />
      </div>
      <div class='layout-item centered padded load-more-wrap'>
        <LinkTo @route='articles' class='button violet'>
          Read more articles...
          <div class='button-ornament'>
            <svg class='arrow-icon medium'>
              <use href='#svg-arrow-medium'></use>
            </svg>
            <svg class='cross-icon small'>
              <use href='#svg-cross-small'></use>
            </svg>
          </div>
        </LinkTo>
      </div>
    </div>
    <SubscribeBanner />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(IndexRouteComponent);
