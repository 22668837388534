import { gql } from 'glimmer-apollo';

export const ARTICLES_BY_QUERY = gql`
  query ($query: String) {
    articles: articleCollection(
      where: {
        OR: [
          { primaryTag: $query }
          { tags_contains_some: [$query] }
          { author: { name_contains: $query } }
          { content_contains: $query }
          { previewText_contains: $query }
          { title_contains: $query }
        ]
      }
    ) {
      items {
        title
        slug
        publishedDate
        previewText
        primaryTag
        tags
        author {
          name
          avatar {
            url
          }
        }
        image {
          url
          description
        }
        content
      }
    }
  }
`;
