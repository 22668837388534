import Route from '@ember/routing/route';
import { useQuery } from 'glimmer-apollo';
import { GET_HOME_PAGE } from '../gql/queries/home-page';

export default class IndexRoute extends Route {
  query = useQuery(this, () => [GET_HOME_PAGE]);
  async model() {
    await this.query.promise;
    return await this.query.data;
  }
}
