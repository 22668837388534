import { template } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { formatDate } from '../../helpers/format-date';
const eq = (a1, b1)=>a1 === b1;
const hash = (k1, v1)=>{
    return {
        [k1]: v1
    };
};
export default template(`
  <div class='post-preview big ember {{if (eq @article.primaryTag "Ember.js") "ember"}}'>
    <LinkTo @route='articles.post' @model='{{@article.slug}}'>
      <div class='post-preview-img-wrap'>
        <figure class='post-preview-img liquid'><img src='{{@article.image.url}}' alt='{{@article.title}}' /></figure>
      </div>
    </LinkTo>
    <LinkTo @route='search' @query={{hash 'tag' @article.primaryTag}} class='tag-ornament'>
      {{@article.primaryTag}}
    </LinkTo>
    <LinkTo @route='articles.post' @model={{@article.slug}} class='post-preview-title'>{{@article.title}}</LinkTo>
    <div class='post-author-info-wrap'>
      <LinkTo @route='search' @query={{hash 'authorName' @article.author.name}}>
        <figure class='user-avatar tiny liquid'>
          <img src='{{@article.author.avatar.url}}' alt='{{@article.author.name}}' />
        </figure>
      </LinkTo>
      <p class='post-author-info small light'>
        By
        <LinkTo @route='search' @query={{hash 'authorName' @article.author.name}} class='post-author'>
          {{@article.author.name}}
        </LinkTo>
        <span class='separator'>|</span>
        {{formatDate @article.publishedDate}}
      </p>
    </div>
    <p class='post-preview-text'>
      {{@article.previewText}}
    </p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
