import { gql } from 'glimmer-apollo';

export const GET_WEEWOO = gql`
  query {
    weewooCollection {
      weeWoos: edges {
        weeWoo: node {
          name
          id
          nodeId
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
