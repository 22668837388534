import { template } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
const hash = (k1, v1)=>{
    return {
        [k1]: v1
    };
};
export default template(`
  <div class='post-open-tags'>
    <div class='tag-list'>
      {{#each @tags as |tag|}}
        <LinkTo @route='search' @query={{hash 'tag' tag}} class='tag-item'>{{tag}}</LinkTo>
      {{/each}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
