import { template } from "@ember/template-compiler";
import md from '../packages/markdown-it';
import Component from '@glimmer/component';
export default class MarkDown extends Component {
    get html() {
        console.log(md.parseInline(this.args.markdown));
        const blockParsedMarkdown1 = md.parse(this.args.markdown);
        let codeBlocks1 = [];
        for (let block1 of blockParsedMarkdown1){
            if (block1.type === 'fence') {
                const info1 = block1.info.split(';').map((s1)=>s1.trim());
                const language1 = info1[0];
                const attrs1 = info1.splice(1);
                codeBlocks1.push({
                    type: 'code',
                    lang: language1,
                    content: block1.content.trim()
                });
            } else if (block1.type === 'paragraph-open') {
                codeBlocks1.push(block1);
            } else {
                codeBlocks1.push(block1);
            }
        }
        console.log('codeblocks', codeBlocks1);
        return md.render(this.args.markdown);
    }
    static{
        template(`
    {{{this.html}}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
