import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import BannerComponent from '../../components/layout/banner/main';
import PopularPosts from '../../components/widget/sidebar/popular-posts';
import TagCloud from '../../components/widget/sidebar/tag-cloud';
import SubscribeBanner from '../../components/layout/subscribe-banner';
import SidebarSearch from '../../components/widget/sidebar/search';
import PaginationComponent from '../../components/layout/pagination';
import Promo from '../../components/widget/sidebar/promo';
import ArticlesList from '../../components/widget/main/articles-list';
let NewsIndexComponent = class NewsIndexComponent extends Component {
    get tags() {
        let arr1 = [];
        this.args.model?.articles.items.forEach((x1)=>(arr1 = [
                ...arr1,
                ...x1.tags
            ]));
        return arr1;
    }
    static{
        template(`
    <BannerComponent @theme='gaming-news' @title='Articles' />
    <div class='layout-content-1 layout-item-3-1 search-pad grid-limit'>
      <div class='layout-body layout-item centered'>
        <ArticlesList @articles={{@model.articles.items}} />
        <!-- <PaginationComponent /> -->
      </div>
      <div class='layout-sidebar layout-item gutter-medium'>
        <SidebarSearch />
        <TagCloud @tags={{this.tags}} />
        <Promo />
        <!-- <PopularPosts />-->
      </div>
    </div>
    <SubscribeBanner />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(NewsIndexComponent);
