import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class SearchController extends Controller {
  queryParams = ['tag', 'authorName', 'q'];

  @tracked tag = null;
  @tracked authorName = null;
  @tracked q = null;

  @tracked model;

  resetController(controller, isExiting, transition) {
    if (isExiting) {
      controller.set('q', null);
      controller.set('tag', null);
      controller.set('authorName', null);
    }
  }
}
