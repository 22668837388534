import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
// @ts-expect-error
import { keyResponder, onKey } from 'ember-keyboard';
import { Input } from '@ember/component';
import autofocus from '../modifiers/autofocus';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
const hash = (k1: string, v1: string)=>{
    return {
        [k1]: v1
    };
};
interface SearchComponentSignature {
    Args: {
        isSearchOpen: boolean;
        closeSearch: (close: boolean) => void;
    };
}
export default // TODO: refactor this to not be dog water. Maybe more alfred like, less full screen silliness.
@keyResponder({
    priority: 2
})
class SearchComponent extends Component<SearchComponentSignature> {
    @service
    readonly router: RouterService;
    @tracked
    query: string = '';
    @action
    search(event1: Event) {
        event1.preventDefault();
        this.router.transitionTo('search', {
            queryParams: {
                q: this.query
            }
        });
        this.closeSearch();
    }
    get isSearchOpen() {
        return this.args.isSearchOpen ?? false;
    }
    // Feels janky.
    @onKey('w')
    @onKey('shift+w')
    @onKey('s')
    @onKey('shift+s')
    noOp(e1: any, ek1: any) {
        ek1.stopPropagation();
        ek1.stopImmediatePropagation();
    }
    @onKey('Escape')
    closeSearch() {
        this.args.closeSearch(false);
    }
    static{
        template(`
    <div class='search-popup open'>
      <svg class='cross-icon big close-button search-popup-close' {{on 'click' (fn @closeSearch false)}}>
        <use href='#svg-cross-big'></use>
      </svg>
      <form class='search-popup-form' {{on 'submit' this.search}}>
        <label for='search' class='accessible-label'>Search</label>
        <Input
          class='input-line {{if this.isSearchOpen "open"}}'
          id='search'
          name='q'
          placeholder='What are you looking for...?'
          @type='text'
          @value={{this.query}}
          {{autofocus}}
        />
      </form>
      <p class='search-popup-text'>Write what you are looking for and press enter to begin your search!</p>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
