import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import PostPreview from '../../layout/post-preview';
export default class ArticlesList extends Component {
    get articles() {
        return this.args.articles || [];
    }
    static{
        template(`
    <div class='layout-item-1'>
      <div class='layout-item'>
        {{#each this.articles as |article|}}
          <PostPreview @type='Ember' @article={{article}} />
        {{/each}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
