
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("velocity/apollo", function(){ return i("velocity/apollo.ts");});
d("velocity/app", function(){ return i("velocity/app.ts");});
d("velocity/config/environment", function(){ return i("velocity/config/environment.js");});
d("velocity/gql/queries/article-post", function(){ return i("velocity/gql/queries/article-post.ts");});
d("velocity/gql/queries/articles-by-author", function(){ return i("velocity/gql/queries/articles-by-author.ts");});
d("velocity/gql/queries/articles-by-query", function(){ return i("velocity/gql/queries/articles-by-query.ts");});
d("velocity/gql/queries/articles-by-tag", function(){ return i("velocity/gql/queries/articles-by-tag.ts");});
d("velocity/gql/queries/home-page", function(){ return i("velocity/gql/queries/home-page.ts");});
d("velocity/gql/queries/weewoo", function(){ return i("velocity/gql/queries/weewoo.ts");});
d("velocity/instance-initializers/apollo", function(){ return i("velocity/instance-initializers/apollo.js");});
d("velocity/packages/markdown-it", function(){ return i("velocity/packages/markdown-it.js");});
d("velocity/packages/mobile-menu", function(){ return i("velocity/packages/mobile-menu.js");});
d("velocity/packages/xm-arc", function(){ return i("velocity/packages/xm-arc.js");});
d("velocity/packages/xm-dropdown", function(){ return i("velocity/packages/xm-dropdown.js");});
d("velocity/packages/xm-progress", function(){ return i("velocity/packages/xm-progress.js");});
d("velocity/router", function(){ return i("velocity/router.ts");});
d("velocity/services/auth", function(){ return i("velocity/services/auth.ts");});
d("velocity/services/current-user", function(){ return i("velocity/services/current-user.ts");});
d("velocity/services/routing", function(){ return i("velocity/services/routing.js");});
d("velocity/supabase", function(){ return i("velocity/supabase.ts");});
d("velocity/services/keyboard", function(){ return i("velocity/services/keyboard.js");});
d("velocity/services/page-title", function(){ return i("velocity/services/page-title.js");});
d("velocity/initializers/set-glimmer-apollo-environment-ember", function(){ return i("velocity/initializers/set-glimmer-apollo-environment-ember.js");});
d("velocity/component-managers/glimmer", function(){ return i("velocity/component-managers/glimmer.js");});
d("velocity/initializers/app-version", function(){ return i("velocity/initializers/app-version.js");});
d("velocity/data-adapter", function(){ return i("velocity/data-adapter.js");});
d("velocity/initializers/ember-data", function(){ return i("velocity/initializers/ember-data.js");});
d("velocity/services/store", function(){ return i("velocity/services/store.js");});
d("velocity/transforms/boolean", function(){ return i("velocity/transforms/boolean.js");});
d("velocity/transforms/date", function(){ return i("velocity/transforms/date.js");});
d("velocity/transforms/number", function(){ return i("velocity/transforms/number.js");});
d("velocity/transforms/string", function(){ return i("velocity/transforms/string.js");});
d("velocity/metrics-adapters/base", function(){ return i("velocity/metrics-adapters/base.js");});
d("velocity/services/metrics", function(){ return i("velocity/services/metrics.js");});
d("velocity/container-debug-adapter", function(){ return i("velocity/container-debug-adapter.js");});
d("velocity/templates/search", function(){ return i("velocity/templates/search.js");});
d("velocity/controllers/search", function(){ return i("velocity/controllers/search.js");});
d("velocity/routes/search", function(){ return i("velocity/routes/search.js");});
d("velocity/templates/about", function(){ return i("velocity/templates/about.js");});
d("velocity/routes/about", function(){ return i("velocity/routes/about.js");});
d("velocity/templates/application", function(){ return i("velocity/templates/application.js");});
d("velocity/routes/application", function(){ return i("velocity/routes/application.js");});
d("velocity/templates/articles/index", function(){ return i("velocity/templates/articles/index.js");});
d("velocity/routes/articles/index", function(){ return i("velocity/routes/articles/index.js");});
d("velocity/templates/articles/post", function(){ return i("velocity/templates/articles/post.js");});
d("velocity/routes/articles/post", function(){ return i("velocity/routes/articles/post.js");});
d("velocity/templates/index", function(){ return i("velocity/templates/index.js");});
d("velocity/routes/index", function(){ return i("velocity/routes/index.js");});
d("velocity/templates/404", function(){ return i("velocity/templates/404.js");});
d("velocity/templates/privacy-policy", function(){ return i("velocity/templates/privacy-policy.js");});
d("velocity/templates/terms-and-conditions", function(){ return i("velocity/templates/terms-and-conditions.js");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("velocity/instance-initializers/setup-fetch", function(){ return i("velocity/instance-initializers/setup-fetch.js");});
  }




if (!runningTests) {
  i("../app").default.create({"name":"velocity","version":"0.0.0+10528771"});
}

