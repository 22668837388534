import { template } from "@ember/template-compiler";
import TopBrand from './footer/top-brand';
import { LinkTo } from '@ember/routing';
export default template(`
  <div class='footer-top-wrap'>
    <div class='footer-top grid-limit'>
      <TopBrand />
    </div>
  </div>
  <div class='footer-bottom-wrap'>
    <div class='footer-bottom grid-limit'>
      <p class='footer-bottom-text'>
        All Rights Reserved 2024</p>
      <p class='footer-bottom-text'>
        <LinkTo @route='terms-and-conditions'>Terms and Conditions</LinkTo>
        <span class='separator'>
          |
        </span>
        <LinkTo @route='privacy-policy'>Privacy Policy</LinkTo>
      </p>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
