import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { LinkTo } from '@ember/routing';
import SocialComponent from '../../components/widget/sidebar/social';
import PopularPostsComponent from '../../components/widget/sidebar/popular-posts';
import TagCloudComponent from '../../components/widget/sidebar/tag-cloud';
import Promo from '../../components/widget/sidebar/promo';
import RelatedArticles from '../../components/widget/main/related-articles';
import PostTagCloud from '../../components/widget/main/post-tag-cloud';
import MarkDown from '../../components/mark-down';
import { formatDate } from '../../helpers/format-date';
import Search from '../../components/widget/sidebar/search';
const hash = (k1, v1)=>{
    return {
        [k1]: v1
    };
};
let NewsPostComponent = class NewsPostComponent extends Component {
    static{
        template(`
    <style>
      .user-avatar.tiny.liquid { overflow: hidden; }
    </style>
    <div class='layout-content-1 layout-item-3-1 grid-limit'>
      <div class='layout-body'>
        <div class='layout-item gutter-big'>
          <div class='post-open ember gaming-news'>
            <figure class='post-open-img liquid'>
              <img src='{{@model.image.url}}' alt='{{@model.title}}' />
            </figure>
            <div class='post-open-content'>
              <div class='post-open-body'>
                <div class='tag-list'>
                  <a href='/search?tag={{@model.primaryTag}}' class='tag-ornament'>{{@model.primaryTag}}</a>
                </div>
                <p class='post-open-title'>{{@model.title}}</p>
                <div class='post-author-info-wrap'>
                  <LinkTo @route='search'>
                    <figure class='user-avatar tiny liquid'>
                      <img src='{{@model.author.avatar.url}}' alt='{{@model.author.avatar.url}}' />
                    </figure>
                  </LinkTo>
                  <p class='post-author-info small light'>By
                    <LinkTo @route='search' @query={{hash 'authorName' @model.author.name}}>
                      {{@model.author.name}}
                    </LinkTo>
                    <span class='separator'>|</span>{{formatDate @model.publishedDate}}
                  </p>
                </div>
                <MarkDown @markdown={{@model.content}} />
              </div>
            </div>
            <PostTagCloud @tags={{@model.tags}} />
          </div>
          <!-- <RelatedArticles />-->
        </div>
      </div>
      <div class='layout-sidebar layout-item gutter-medium'>
        <Search />
        <SocialComponent />
        <TagCloudComponent @tags={{@model.tags}} />
        <Promo />
        <!-- <PopularPostsComponent /> -->
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(NewsPostComponent);
