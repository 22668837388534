import Service from '@ember/service';
import { service } from '@ember/service';
import supabase from 'velocity/supabase';
import config from 'velocity/config/environment';

import type { SupabaseClient } from '@supabase/supabase-js';
import type { Provider } from '@supabase/supabase-js';
import type RouterService from '@ember/routing/router-service';

export default class AuthService extends Service {
  @service declare readonly router: RouterService;
  client: SupabaseClient;

  constructor(...args: any[]) {
    super(...args);

    this.client = supabase;
  }

  async signInWithProvider(provider: Provider) {
    const redirectTo = window.location.href;
    const response = await this.client.auth.signInWithOAuth({
      provider,
      options: {
        redirectTo,
      },
    });

    return response.data;
  }

  async logout() {
    const { error } = await this.client.auth.signOut();
    window.location.href = config.baseUrl;
  }
}

declare module '@ember/service' {
  interface Registry {
    auth: AuthService;
  }
}
