import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import ResultComponent from '../components/search/result';
import SocialComponent from '../components/widget/sidebar/social';
import PopularPostsComponent from '../components/widget/sidebar/popular-posts';
import PaginationComponent from '../components/layout/pagination';
import Promo from '../components/widget/sidebar/promo';
import Search from '../components/widget/sidebar/search';
const eq = (a1, b1)=>a1 === b1;
let SearchRouteComponent = class SearchRouteComponent extends Component {
    get searchTerm() {
        return this.args.controller.q || this.args.controller.authorName || this.args.controller.tag;
    }
    get resultCount() {
        return this.args.model?.length;
    }
    static{
        template(`
    <div class='banner-wrap forum-banner'>
      <div class='banner grid-limit'>
        <h2 class='banner-title'>Search Results</h2>
      </div>
    </div>
    <div class='layout-content-1 layout-item-3-1 grid-limit'>
      <div class='layout-body'>
        <div class='section-title-wrap blue'>
          <h2 class='section-title medium'></h2>
        </div>
        <div class='filter-heading'>
          <p class='filter-heading-text'><span class='highlight'>{{this.resultCount}}</span>
            Result{{unless (eq this.resultCount 1) 's'}}
            found for: "<span class='highlight'>{{this.searchTerm}}</span>"</p>
        </div>
        <div class='post-preview-showcase grid3-1col centered'>
          {{#each @model as |article|}}
            <ResultComponent @article={{article}} />
          {{/each}}
        </div>
        <!--  <PaginationComponent /> -->
      </div>
      <div class='layout-sidebar layout-item gutter-medium'>
        <Search />
        <SocialComponent />
        <Promo />
        <PopularPostsComponent />
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(SearchRouteComponent);
