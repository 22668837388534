import { template } from "@ember/template-compiler";
export default template(`
  <div class='footer-top-brand'>
    <div class='logo negative'>
      <div class='logo-text'>
        <h2 class='logo-title'>
          TerminalVelo<span class='highlight'>.city</span>
        </h2>
        <p class='logo-info'></p></div>
    </div>
    <div class='social-links'>
      <a href='https://twitter.com/jhliberty' target='_blank' class='bubble-ornament twt'>
        <svg class='twitter-icon'>
          <use href='#svg-twitter'></use>
        </svg>
      </a>
      <a href='https://twitch.tv/undarkgaming' target='_blank' class='bubble-ornament twitch'>
        <svg class='twitch-icon big'>
          <use href='#svg-twitch'></use>
        </svg>
      </a>
      <a href='https://github.com/jhliberty' target='_blank' class='bubble-ornament black'>
        <svg class='github-icon'>
          <use href='#svg-github'></use>
        </svg>
      </a>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
