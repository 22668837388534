import { gql } from 'glimmer-apollo';

export const ARTICLES_BY_AUTHOR = gql`
  query ($name: String) {
    searchedAuthor: authorCollection(where: { name: $name }) {
      items {
        name
        linkedFrom {
          articles: articleCollection(limit: 6) {
            items {
              title
              slug
              publishedDate
              previewText
              primaryTag
              tags
              author {
                name
                avatar {
                  url
                }
              }
              image {
                url
                description
              }
              content
            }
          }
        }
      }
    }
  }
`;
