import { gql } from 'glimmer-apollo';

export const ARTICLES_BY_TAG = gql`
  query ($tag: String) {
    articles: articleCollection(
      where: { OR: [{ primaryTag: $tag }, { tags_contains_some: [$tag] }] }
    ) {
      items {
        title
        slug
        publishedDate
        previewText
        primaryTag
        tags
        author {
          name
          avatar {
            url
          }
        }
        image {
          url
          description
        }
        content
      }
    }
  }
`;
