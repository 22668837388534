import { gql } from 'glimmer-apollo';

export const GET_HOME_PAGE = gql`
  query {
    featuredArticles: articleCollection(limit: 1) {
      items {
        title
        slug
        publishedDate
        previewText
        primaryTag
        tags
        author {
          name
          avatar {
            url
          }
        }
        image {
          url
          description
        }
        content
      }
    }
    articles: articleCollection(limit: 3) {
      items {
        title
        slug
        publishedDate
        previewText
        primaryTag
        tags
        author {
          name
          avatar {
            url
          }
        }
        image {
          url
          description
        }
        content
      }
    }
    recentArticles: articleCollection(limit: 3) {
      items {
        title
        slug
        publishedDate
        previewText
        primaryTag
        tags
        author {
          name
          avatar {
            url
          }
        }
        image {
          url
          description
        }
        content
      }
    }
  }
`;
